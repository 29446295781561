@import "src/variables.module";

.loading {
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;

    >svg {
        height: 100%;
        z-index: 2;
    }
}

.wrapper {
    position: relative;
    width: 100%;
}

.responsive {
    overflow-x: auto;
}

.table {
    width: 100%;
}

.tableCell {
    padding: $baseSpace/2;
    border-bottom: 1px solid $colorSpecial;
}

.textInnerCell {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tableCellBtns {
    >div {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
}

.tableCellBtn {
    margin: $baseSpace/3;
}

.tableRow.rowHover {
    cursor: pointer;
    &:hover {
        .tableCell {
            background-color: $lightColor;
        }
    }
}

.tableRowHeader {
    font-weight: bold;
    >* {
        padding-bottom: $baseSpace;
    }
}

.secondCaption {
    font-weight: bold;
    display: none;
}

.noItems {
    margin-top: $baseSpace;
}

@media all and (max-width: $mSmallWidth) {

    .tableCell:not(:last-child) {
        border-bottom: none;
    }

    .tableCell:last-child {
        padding-bottom: $baseSpace;
    }

    .tableRow {
        margin-bottom: $baseSpace;
    }

    .tableRowHeader {
        display: none;
    }

    .secondCaption {
        display: inline-block;
    }
}