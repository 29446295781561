@import "src/variables.module";

.service {
  flex: 33.33333%;
  padding: 0 $baseSpace;

  @media all and (max-width: $largeWidth) {
    flex: 50%;
  }

  @media all and (max-width: $xSmallWidth) {
    flex: 100%;
  }
}

.heading {
  text-transform: uppercase;
  font-weight: bold;
}

.item {
  margin-top: $baseSpace;
}