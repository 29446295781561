@import "src/variables.module";

.adminButtons {
    display: flex;
    justify-content: flex-end;
    margin-right: -2px;
    margin-left: -2px;

    >* {
        margin: 2px;
    }
}

.ingredientsGrid {
    margin-top: $baseSpace;
}