@import "src/variables.module";

.imagesWrap {

}

.imageWrap {
  border: 2px solid $colorSpecial;
  border-radius: $smallBorderRadius;
  margin: 2px 2px $baseSpace 2px;
  padding: $baseSpace;
  display: flex;
  flex-direction: column;
}

.header {
  color: $colorSpecial;
  transition: color $transitionDuration ease;
  cursor: pointer;
  font-size: 1.2em;
  align-self: flex-start;

  &:hover {
    color: $colorLogo;
  }
}

.footer {

}

.image {
  max-width: 100%;
  max-height: 200px;
  border-radius: $smallBorderRadius;
  margin: 10px 0;
}

.imageSrcWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}