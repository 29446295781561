@import "src/variables.module";

.services {
  display: flex;
  margin-top: $baseSpace*4;
  flex-wrap: wrap;

  @media all and (max-width: $largeWidth) {
    >*:nth-child(3) {
      margin-top: $baseSpace*2;
    }
  }

  @media all and (max-width: $xSmallWidth) {
    >*:nth-child(2) {
      margin-top: $baseSpace*3;
    }

    >*:nth-child(3) {
      margin-top: $baseSpace*3;
    }
  }
}