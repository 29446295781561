@import "src/variables.module";

.section {
  width: 100%;
  position: relative;
  min-height: 100vh;
  background-color: black;
}

.header {
  margin-bottom: $baseSpace*3;
}