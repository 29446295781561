@import "src/variables.module";

.outerSection {
  display: flex;
  justify-content: center;
  position: relative;
}

.innerSection {
  max-width: $smallWidth;
  flex-grow: 1;
  padding: 0 $baseSpace*2;

  @media all and (max-width: $xSmallWidth) {
    width: 100%;
    max-width: unset;
  }
}