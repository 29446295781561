@import "src/variables.module";

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 2px;
}

$fakeSize: 24px;
.fake {
  width: $fakeSize; height: $fakeSize;
  max-width: $fakeSize; min-width: $fakeSize;
  background-color: white;
  border-radius: $smallBorderRadius;
  border: 2px solid $colorSpecial;
  position: relative;

  &:after, &:before {
    content: "";
    height: 2px; width: 100%;
    background-color: $colorSpecial;
    border-radius: 2px;
    position: absolute;
    top: 9px; left: 0;
    opacity: 0;
    transition: opacity $transitionDuration ease, transform $transitionDuration ease, background-color $transitionDuration ease;
  }
}

.input {
  display: none;

  &:checked ~ .fake {
    &:after, &:before {
      opacity: 1;
      transform: rotate(45deg);
      background-color: $colorLogo;
    }

    &:before {
      transform: rotate(-45deg);
    }
  }
}

.caption {
  margin-bottom: 0;
}