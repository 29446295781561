@import "src/variables.module";

.section {
    position: relative;
    overflow: hidden;
}

.centeredSection {
    width: $fullWidth;
    margin: auto;
    padding: 0 $baseSpace*4;

    @media all and (max-width: $fullWidth) {
        width: 100%;
    }

    @media all and (max-width: $smallWidth) {
        padding: 0 $baseSpace*2;
    }
}