@import "src/variables.module";

.menu {
  display: flex;
  width: 100%;

  @media all and (max-width: $xSmallWidth) {
    flex-direction: column;
  }
}

.content {
  width: 100%;
  margin-top: $baseSpace*1.5;
}