@import "src/variables.module";

$padding: $baseSpace*3;

.section {
  background-color: black;
  color: white;
  padding-top: $padding;
  padding-bottom: $padding;
  text-align: center;
}

.terms {
  margin-top: $baseSpace*2;
  text-transform: uppercase;
}