@import "src/variables.module";

.heading {
  margin-bottom: 0;

  &:not(:first-child) {
    margin-top: $baseSpace*2;
    margin-bottom: $baseSpace;
  }
}

.alert {
  margin-bottom: $baseSpace;
}

.infoAlert {
  margin: $baseSpace 0;
}