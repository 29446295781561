@import "src/variables.module";

.header {
  display: flex;
  align-items: stretch;
}

$numberSize: 80px;

.numbersWrap {
  display: flex;
  @media all and (max-width: $verySmall) {
    flex-direction: column;
  }
}

.numberItem {
  display: flex;
  flex-grow: 1;
  color: #6b6b6b;
  align-items: flex-start;
  justify-content: center;
  transition: color $transitionDuration ease;
  padding: $baseSpace/2;

  @media all and (max-width: $verySmall) {
    align-items: flex-start;
    justify-content: flex-start;
  }

  &.active {
    color: white;
  }

  &.done {
    color: $colorLogo;
    cursor: pointer;
  }
}

.number {
  font-size: 1.7em;
  font-weight: 600;

  @media all and (max-width: $xxSmallWidth) {
    font-size: 1.2em;
  }
}

.heading {
  @media all and (max-width: $xxSmallWidth) {
    font-size: 0.8em;
  }
}

.content {
  padding: $baseSpace*2 $baseSpace;
  color: white;
}

.voucherBlock {
  text-align: center;
}

.iconWrap {
  font-size: 6em;
  color: $colorLogo;
  margin-bottom: $baseSpace;
}