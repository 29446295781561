@import "src/variables.module";

.h1 {
  font-size: 3em;
  margin-top: $baseSpace*2;
  margin-bottom: $baseSpace;
  font-weight: 600;
  position: relative;

  @media all and (max-width: $xSmallWidth) {
    font-size: 2.5em;
  }
}

.centerLine {
  left: calc(50% - 3rem);
}

.anchor {
  position: absolute;
  left: 0;
}

.line {
  margin-top: $baseSpace;
}