@import "src/variables.module";

.table {
  display: table;
}

.row {
  display: table-row;
}

.cell {
  display: table-cell;
}

@media all and (max-width: $mSmallWidth) {
  .table, .row {
    display: flex;
    flex-direction: column;
  }

  .cell {
    display: block;
  }
}