@import "src/variables.module";

.iconWrap {
  margin-top: $baseSpace*2;
  margin-bottom: $baseSpace*2;
  font-size: 6em;
  text-align: center;

  .success {
    color: $successColor;
  }

  .failed {
    color: $errorColor;
  }
}

.alert {
  text-align: center;
}

.paragraph {
  line-height: 1.5em;
  text-indent: 2em;
}

.line {
  width: 100px;
  height: 4px;
  border-radius: 2px;
  background: $colorLogo;
  margin: $baseSpace*4 auto $baseSpace*2 auto;
}