@import "src/variables.module";

.table {
  width: 100%;
}

.row {
  &:first-child {
    .cell {
      padding-top: 0;
    }
  }

  @media all and (max-width: $mSmallWidth) {
    padding: $baseSpace 0;
    border-bottom: 1px solid $colorSpecial;

    &:last-child {
      border-bottom: none;
    }
  }
}

.cell {
  display: table-cell;
  padding: $baseSpace 0;
  border-bottom: 1px solid $colorSpecial;

  &:first-child {
    font-weight: 600;
  }

  >div {
    padding: 0 $baseSpace;
  }

  @media all and (max-width: $mSmallWidth) {
    border-bottom: none;
    padding: $baseSpace/5 0;
  }
}

.attention {
  color: $errorColor;
}

.weightButtonWrap {
  margin-bottom: $baseSpace*2;
}