@import "src/variables.module";

.crossHeader {
  display: flex;
  justify-content: space-between;
  padding-top: $baseSpace*2;
  align-items: center;
}

.heading {
  font-size: 2.3em;
  color: $colorLogo;
  text-transform: uppercase;
  flex-grow: 1;
  padding-right: $baseSpace;

  @media all and (max-width: $xSmallWidth) {
    font-size: 1.8em;
  }

  @media all and (max-width: $xxSmallWidth) {
    font-size: 1.4em;
  }
}

.cross {
  flex-basis: 50px;
  width: 50px;
  height: 50px;
  min-width: 50px;
  position: relative;
  cursor: pointer;

  @media all and (max-width: $xxSmallWidth) {
    width: 30px;
    height: 30px;
    flex-basis: 30px;
    min-width: 30px;
  }

  div {
    position: absolute;
    top:25px; left:0;
    width: 50px;
    height: 3px;
    background-color: $colorLogo;
    border-radius: 5px;
    transition: transform $transitionDuration ease;

    @media all and (max-width: $xxSmallWidth) {
      top: 15px;
      width: 30px;
    }
  }

  &:not(.wait) {
    div:first-child {
      transform: rotate(45deg);
    }

    div:last-child {
      transform: rotate(-45deg);
    }
  }
}