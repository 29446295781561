@import "src/variables.module";

.ingredient {
  margin: $baseSpace 0;
  background: white;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow:    0px 0px 4px 0px rgba(50, 50, 50, 0.75);
  box-shadow:         0px 0px 4px 0px rgba(50, 50, 50, 0.75);
  border-radius: 10px;
  padding: $baseSpace;
}

.ingredientName {
  font-weight: bold;
  color: $colorLogo;
  text-transform: uppercase;
}

.amountInputsWrap {
  display: flex;

  @media all and (max-width: $xSmallWidth) {
    flex-direction: column;
  }
}

.amountInputs {
  display: flex;

  &:first-child {
    padding-right: $baseSpace/2;
  }

  .amountInputWrap {
    padding-right: $baseSpace/2;
  }

  .amountInput {
    width: 100%;
    max-width: none;
  }
}

.ingredientButtons {
  >* {
    margin: 5px;
  }
}