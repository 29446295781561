// responsive
$fullWidth: 1460px;
$largeWidth: 1000px;
$mediumWidth: 900px;
:export { mediumWidth: 900; }
$smallWidth: 760px;
:export { smallWidth: 760; }
$xSmallWidth: 600px;
:export { xSmallWidth: 0; }
$mSmallWidth: 500px;
$xxSmallWidth: 400px;
$verySmall: 330px;
:export { verySmall: 330; }


// colors
$colorSpecial: #d3a657;
:export {colorSpecial: $colorSpecial}
$colorLogo: #c90a58;
:export {colorLogo: $colorLogo}
$transparentColorLogo: rgba(201,10,88,0.7);
$goldGradient: linear-gradient(to right, #AF8747, #DCBD77);
:export {goldGradient: $goldGradient}
$lightColor: #d1d3d4;
:export {lightColor: $lightColor}
$slightyDarkGrayColor: rgb(100, 100, 100);
:export {slightlyDarkGrayColor: $slightyDarkGrayColor}
$darkGrayColor: rgb(35,35,35);
$errorColor: #ff0000;
$transparentErrorColor: rgba(255, 0, 0, 0.2);
$successColor: #009900;
$transparentSuccessColor: rgba(0, 255, 0, 0.2);

// spaces
$baseSpace: 15px;
:export { baseSpace: 15; }
$largeSpace: 80px;
:export { largeSpace: 80; }

// border radius
$smallBorderRadius: 5px;

//animation
$transitionDuration: 300ms;

//other
$diagonalHeight: $largeSpace*4;