@import "src/variables.module";

.wrap {
  display: flex;
  align-items: center;
}

.label {
  flex-grow: 1;
  display: flex;
  align-items: center;

  span {
    padding-right: $baseSpace;
  }

  input {
    flex-grow: 1;
  }
}

.button {
  padding-left: $baseSpace;
}