@import "src/variables.module";

.info {
  font-size: 1.2em;
  margin: $baseSpace 0;
  color: $colorLogo;

  &:first-child {
    margin-bottom: 0;
  }
}

.input {
  width: 99%;
}

.inputsWrap {

  >div {
    flex-grow: 1;

    &:nth-child(2) {
      margin-left: 5px;
      margin-right: 5px;

      @media all and (max-width: $xSmallWidth) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}