@import "src/variables.module";

.alert {
  border-radius: $smallBorderRadius;
  padding: $baseSpace;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0;
  font-size: 0.8em;
  border: 2px solid;

  &.failed {
    border-color: $errorColor;
    background-color: $transparentErrorColor;
    color: $errorColor;
  }

  &.success {
    border-color: $successColor;
    background-color: $transparentSuccessColor;
    color: $successColor;
  }
}