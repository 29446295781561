@import "../../variables.module";

.regSign {
  padding-top: $baseSpace*2;

  @media all and (max-width: $xSmallWidth) {
    &>section {
      padding-left: $baseSpace !important;
      padding-right: $baseSpace !important;
    }
  }
}

.innerRegSign {
  padding: $largeSpace/2;
  margin: $baseSpace;

  display: flex;
  align-items: center;

  position: relative;
  box-sizing: border-box;

  $border: 4px;
  background: white;
  background-clip: padding-box;
  border: solid $border transparent;
  border-radius: 1em;

  >div {
    width: 100%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$border;
    border-radius: inherit;
    background: $goldGradient;
  }

  @media all and (max-width: $xSmallWidth) {
    margin: 0;
    padding: $baseSpace;
  }
}

.innerRegSignNoBorder {
  &:before {
    background: none;
  }
}

.linePack {
  position: relative;
  height: 175px;
  overflow: hidden;
}

.line {
  position: absolute;
  left: calc( 50% - 297px );
  top: 0;
  width: 594px;
  height: 248px;
}

.registrationBlock {
  background-color: black;
  color: white;
  padding-top: $largeSpace;
  padding-bottom: $largeSpace;
}

.copyright {
  background-color: $darkGrayColor;
}