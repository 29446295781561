@import "src/variables.module";

.wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  max-height: 0;
  transition: max-height $transitionDuration ease;
}

.text {
  text-align: center;
  width: 100%;
  padding-bottom: 5px;
  font-size: 0.8em;
  color: $colorSpecial;
  text-transform: uppercase;
}

.bar {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 15px;
  border-radius: 15px;
  border: 2px solid $colorSpecial;
}

.progress {
  background-color: $colorLogo;
  position: absolute;
  top: 0; left: 0;
  height: 100%;
  transition: width $transitionDuration linear;
}