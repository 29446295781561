@import "src/variables.module";
@import "src/mixins";

.section {
  position: relative;
}

.beRaspberryBlock {
  background-color: black;
  color: white;
  text-align: center;
  padding-top: $largeSpace;
  padding-bottom: $largeSpace;
  margin-bottom: $largeSpace;

  p {
    margin-left: auto;
    margin-right: auto;

    &:first-child {
      margin-top: 0;
    }
  }

  font-weight: 600;
}

.backgroundWrap {
  position: absolute;
  width: 100%; height: $largeSpace*8;
  top: -$largeSpace*2.2; left: 0;
  z-index: -1;
}

.backgroundImage {
  position: relative;
  display: block;
  height: $largeSpace*8;
  float: right;

  @media all and (max-width: $xSmallWidth) {
    left: 70%;
  }
}