@import "src/variables.module";

.oneImage {
  margin-top: $baseSpace;
  margin-bottom: $baseSpace;
  cursor: pointer;
}

.imageWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 500px;
    border-radius: $smallBorderRadius;
  }
}

.oneImageDescription, .footer {
  color: $slightyDarkGrayColor;
  border-bottom: 1px solid $slightyDarkGrayColor;
  padding-bottom: $baseSpace/3;
}

.oneImageDescription {
  margin-top: $baseSpace;
}

.imagesWrap {
  display: flex;
  margin-left: -$baseSpace;
  margin-right: -$baseSpace;

  .image {
    margin: $baseSpace;
    cursor: pointer;
  }

  @media all and (max-width: $xSmallWidth) {
    margin-left: -$baseSpace/2;
    margin-right: -$baseSpace/2;

    .image {
      margin: $baseSpace/2;
    }
  }

  @media all and (max-width: $xxSmallWidth) {
    flex-wrap: wrap;
  }
}

.photoGallery {
  margin-top: $baseSpace;
  margin-bottom: $baseSpace;

  .footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    cursor: pointer;
  }
}