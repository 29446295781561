@import "src/variables.module";

.noPlan {
  text-align: center;
  margin-bottom: $baseSpace;
}

.h3 {
  margin-top: $baseSpace*3;
  &:first-of-type {
    margin-top: 0;
  }
}

.cancelRecurrenceWrap {
  margin-bottom: $baseSpace*2;
}

.voucherBlock {
  text-align: center;
}

.iconWrap {
  font-size: 6em;
  color: $colorLogo;
  margin-bottom: $baseSpace;
}