@import "src/variables.module";

.withoutBurger {}

.menuItem {
  text-transform: uppercase;
  cursor: pointer;
  transition: color $transitionDuration ease;

  &:hover {
    color: $colorLogo;
  }

  svg {
    font-size: 1.7em;
  }
}

.special {
  background-color: $colorSpecial;
  background-image: $goldGradient;
  border-radius: 2em;
  padding: 5px 20px;
  color: white;

  @media all and (max-width: $smallWidth) {
    &:not(.withoutBurger) {
      background-color: transparent;
      background-image: none;
      color: $colorLogo !important;
      font-weight: bold;
      padding: 0;
      border-radius: 0;
    }
  }

  a {
    text-decoration: none;
  }
}