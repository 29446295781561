@import "src/variables.module";

.section {
  display: flex;

  >div {
    flex: 50%;
  }

  @media all and (max-width: $mediumWidth) {
    flex-direction: column;

    >div:nth-child(1) {
      order: 2;
    }
    >div:nth-child(2) {
      order: 1;
    }
  }
}