@import "src/variables.module";

.label {
  display: flex;
  flex-direction: column;
}

$spaceInputCaption: 2px;

.caption {
  text-transform: uppercase;
  font-weight: 600;
  color: $colorSpecial;
  order: 1;
  margin: 0 $baseSpace/2 $spaceInputCaption $baseSpace/2;
  transition: color $transitionDuration ease;
}

.error {
  color: $errorColor;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8em;
  padding: 0 $baseSpace/2 0 $baseSpace/2;
  z-index: -1;
  overflow: hidden;
}

input, select, textarea {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type=text], input[type=password], input[type=email], select, input[type=file], textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 1;
  border: 2px solid $colorSpecial;
  outline-style: none;
  margin: 2px;
  font-size: 1em;
  padding: $baseSpace/2;
  border-radius: $smallBorderRadius;
  color: $colorSpecial;
  order: 2;
  transition: color $transitionDuration ease, border-color $transitionDuration ease;

  &:focus {
    border-color: $colorLogo;
    color: $colorLogo;

    & + .caption {
      color: $colorLogo;
    }
  }
}

input[type=text], input[type=password], input[type=email], select, input[type=file] {
  height: 41px;
}

textarea {
  resize: none;
  height: 200px;
}

.inputGroup {
  margin-top: $baseSpace;
  margin-bottom: $baseSpace;

  &.invalid {
    input[type=text], input[type=password], input[type=email], select, input[type=file], textarea {
      border-color: $errorColor;
      color: $errorColor;
    }
  }
}