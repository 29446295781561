@import "src/variables.module";

.wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

$size: 193px;

.item {
  flex-basis: $size;
  margin: $baseSpace;
  cursor: pointer;
  position: relative;

  &::after {
    content: "\21B3 Otevřít";
    font-weight: bold;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0; left: 0;
    width: $size; height: $size;
    border-radius: $smallBorderRadius;
    background-color: $transparentColorLogo;
    opacity: 0;
    transition: opacity $transitionDuration ease;
  }

  .image {
    transition: filter $transitionDuration ease;
  }

  &:hover {
    &::after {
      opacity: 1;
    }

    .image {
      filter: blur(5px);
    }
  }
}

.image {
  width: 100%;
  height: $size;
  background-color: $lightColor;
  border-radius: $smallBorderRadius;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  position: relative;

  .wrapClassName {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
    }
  }
}

.subtitle {
  text-transform: uppercase;
  color: $colorSpecial;
  font-size: 0.8em;
  margin-top: 4px;
}

.title {

}

.bottomPart, .topPart {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: $baseSpace;
}

.topPart {
  padding-bottom: $baseSpace;
}

.emptyResult {
  color: $colorLogo;
  margin-top: $baseSpace;
  border: 2px solid $colorLogo;
  border-radius: $smallBorderRadius;
  padding: $baseSpace $baseSpace*2;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 1.4em;
    margin-right: $baseSpace/1.5;
  }
}