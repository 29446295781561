@import "src/variables.module";

.pack {
  display: flex;
  align-items: center;
  margin-top: $baseSpace;
}

$numberSize: 60px;
.number {
  background-color: $colorLogo;
  width: $numberSize; height: $numberSize;
  min-width: $numberSize; min-height: $numberSize;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 2em;
}

.content {
  padding-left: $baseSpace;
}