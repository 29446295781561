@import "src/variables.module";

$burgerHeight: 4px;
$burgerSpace: 6px;

.menuItems {
  display: flex;
  align-items: center;

  &:not(.withoutBurger) {
    height: 100%;
  }

  @media all and (max-width: $smallWidth) {
    &:not(.withoutBurger) {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.9);
      padding: $baseSpace*2;
    }
  }
}

.activeMenu {
  display: flex;

  @media all and (max-width: $smallWidth) {
    &:not(.withoutBurger) {
      display: block;
    }
  }
}

.menuItem {
  margin-left: $baseSpace;
  &:first-child { margin-left: 0; }

  @media all and (max-width: $smallWidth) {

    &:not(.withoutBurger) {
      color: black;
      margin-left: 0;
      border-top: 1px solid black;
      margin-top: $baseSpace;
      padding-top: $baseSpace;

      &:first-child {
        border-top: none;
        margin-top: $burgerHeight*3+$burgerSpace*2+$baseSpace;
      }
    }
  }

  @media all and (max-width: $mediumWidth) {
    &.withoutBurger {
      margin-left: 0;
      margin-top: $baseSpace;
    }
  }
}

.iconsWrap {
  display: flex;
  align-items: center;
  margin-left: $baseSpace;

  @media all and (max-width: $smallWidth) {
    margin-left: 0;
    margin-top: $baseSpace*2;

    .menuItem, .menuItem:first-child {
      border-top: 0;
      margin-top: 0;
      padding-top: 0;
      font-size: 1.1em;
    }

    .menuItem:first-child {
      margin-right: $baseSpace;
    }
  }
}

.burgerWrap {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.burger {
  position: relative;
  color: white;
  width: 35px; height: $burgerHeight*3+$burgerSpace*2;
  cursor: pointer;
  z-index: 1;

  div {
    position: absolute;
    width: 100%; height: $burgerHeight;
    background-color: white;
    border-radius: $burgerHeight;
    transition: transform $transitionDuration ease, background-color $transitionDuration ease;

    &:nth-child(2) {
      top: $burgerSpace+$burgerHeight;
    }
    &:nth-child(3) {
      top: $burgerSpace*2+$burgerHeight*2;
    }
  }

  @media all and (min-width: $smallWidth) {
    display: none;
  }
}

.burgerBlack {
  div {
    background-color: black;
  }
}

.activeBurger {
  div {
    background-color: $colorLogo;

    &:nth-child(1) {
      transform: translate(0, $burgerHeight+$burgerSpace) rotate(45deg);
    }
    &:nth-child(2) {
      transform: rotate(45deg);
    }
    &:nth-child(3) {
      transform: translate(0, -($burgerHeight+$burgerSpace)) rotate(-45deg);
    }
  }
}

.withoutBurger {
  @media all and (max-width: $mediumWidth) {
    display: block;
    text-align: center;
  }
}