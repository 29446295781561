@import "variables.module";

p {
  max-width: $smallWidth;
  margin-top: $baseSpace;
  margin-bottom: $baseSpace;
}

h3 {
  font-weight: bold;
  margin: $baseSpace 0;
}

.fullPageLoading {
  position: fixed;
  z-index: 1000;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.line {
  position: relative;
  display: block;
  height: 5px; width: 6rem;
  background-color: $colorSpecial;
  background-image: $goldGradient;
  border-radius: 5px;
}

.flashMessageWrap {
  position: fixed;
  left: 0; bottom: 0;
  width: 100%;
  padding: $baseSpace;
  display: flex;
  flex-direction: column;
  z-index: 2001;
}


@keyframes flashMessageAnim {
  from {width: 100%;}
  to {width: 0;}
}

$infoFlashColor: $colorSpecial;
$successFlashColor: $successColor;
$errorFlashColor: $errorColor;
$flashBackground: rgba(255,255,255,0.9);
.flashMessage {
  padding: $baseSpace;
  border: 1px solid $infoFlashColor;
  border-radius: $smallBorderRadius;
  color: $infoFlashColor;
  background-color: $flashBackground;
  font-weight: 600;
  margin-top: $baseSpace/2;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0; left: 0;
    width: 100%;
    height: $baseSpace/4;
    background-color: $infoFlashColor;
    animation-name: flashMessageAnim;
    animation-duration: 10s;
    animation-timing-function: linear;
  }

  &.success {
    border-color: $successFlashColor;
    background-color: $flashBackground;
    color: $successFlashColor;

    &:after {
      background-color: $successFlashColor;
    }
  }

  &.failed {
    border-color: $errorFlashColor;
    background-color: $flashBackground;
    color: $errorFlashColor;

    &:after {
      background-color: $errorFlashColor;
    }
  }
}

.loginModal {
  z-index: 2000;
  background-color: black;
}

.successColored {
  color: $successColor;
}
