@import "src/variables.module";

.wrap {
  border: 1px solid white;
  border-radius: $smallBorderRadius;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.card {
  flex: 30%;
  border-left: 1px solid white;
  padding: $baseSpace*1.5;
  text-align: center;
  position: relative;
  overflow: hidden;

  * {
    z-index: 1;
  }

  &:first-child {
    border-left: none;
  }

  @media all and (max-width: $smallWidth) {
    flex: 100%;
    border-left: none;
    border-top: 1px solid white;

    &:first-child {
      border-top: none;
    }
  }

  .heading {
    text-transform: uppercase;
    font-size: 1.9em;
    font-weight: 600;
    position: relative;
    transition: color $transitionDuration ease;
  }

  .price {
    color: $colorSpecial;
    font-weight: 600;
    margin: $baseSpace 0;
    font-size: 1.2em;
    position: relative;
    transition: color $transitionDuration ease;
  }

  .duration {
    position: relative;
    transition: color $transitionDuration ease;
  }

  .chooseBtn {
    display: block;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-image: $goldGradient;
    opacity: 0;
    transition: opacity $transitionDuration ease;
  }

  &:hover {
    .heading, .price, .duration {
      color: $colorLogo;
    }

    &:after {
      opacity: 1;
    }
  }
}

.voucher {
  flex: 100%;
  background-color: white;
  color: black;
  padding: $baseSpace;

  .label {
    font-weight: 600;
  }

  @media all and (max-width: $xSmallWidth) {
    .lineTextInput {
      flex-direction: column;

      &>label {
        flex-direction: column;

        span {
          padding-right: 0;
        }

        input {
          width: 100%;
          margin: $baseSpace 0;
        }
      }

      .button {
        padding-left: 0;
      }
    }
  }
}

.alert {
  margin-top: $baseSpace;
}

.star {
  font-style: italic;
  margin-top: 4px;
  font-size: 0.8em;
}