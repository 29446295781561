@import "src/mixins";
@import "src/variables.module";

.button {
  @include fontFamily;
  @include formPartBorders;

  color: white;
  background-color: $colorLogo;
  font-weight: 600;
  padding: 10px 40px;
  cursor: pointer;
  text-decoration: none;
  outline-style: none;
  position: relative;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .frontIcon {
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .textContent {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;

      >div:first-letter {
        text-transform: uppercase;
      }
    }
  }

  &.small {
    font-size: 0.8em;
    padding: 5px 20px;
    text-transform: uppercase;
  }

  &.selected {
    background-image: $goldGradient;
  }

  @include whiteShadowedText;

  &:hover {
    @include whiteTextShadow;
  }

  &:active {
    @include whiteTextShadowDoubled;
  }

  &:disabled {
    cursor: default;

    &:hover, &:active {
      @include whiteShadowedText;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0,0,0,0.4);
      @include formPartBorders;
    }
  }
}

.loadingState {
  &:disabled {
    &:after {
      background-color: transparent;
    }
  }
}

.loading {
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 30px;
    width: 30px;
  }
}