@import "src/variables.module";
@import "src/mixins";

.item {
  border: 3px solid $colorSpecial;
  border-top: none;
  border-left: none;
  $sidesPadding: $baseSpace*2;
  padding: $baseSpace $sidesPadding;
  flex-grow: 1;
  color: $colorSpecial;
  text-transform: uppercase;
  font-weight: 600;
  transition:
          background-color $transitionDuration ease,
          color $transitionDuration ease,
          border-color $transitionDuration ease;
  cursor: pointer;

  &:last-child {
    border-right: none;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &.active {
    background-color: $colorLogo;
    color: white;
    border-color: $colorLogo;
    border-right-color: $colorSpecial;
    cursor: default;
  }

  &:hover:not(.active) {
    background-color: $colorSpecial;
    color: white;
    border-color: $colorSpecial;
  }

  @media all and (max-width: $xSmallWidth) {
    border-radius: $smallBorderRadius;
    border: 3px solid $colorSpecial;
    margin-bottom: $baseSpace/2;

    &:last-child {
      border-right: 3px solid $colorSpecial;
    }

    &.active {
      border-right: none;
    }
  }
}