@font-face {
  font-family: Montserrat;
  src: url("files/fonts/Montserrat-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat;
  src: url("files/fonts/Montserrat-Light.ttf");
  font-weight: 200;
}

@font-face {
  font-family: Montserrat;
  src: url("files/fonts/Montserrat-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Montserrat;
  src: url("files/fonts/Montserrat-SemiBold.ttf");
  font-weight: 600;
}

body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.noscroll {
    overflow: hidden;
  }
}

*, *:before, *:after {
  box-sizing: border-box;
}

a, a:active, .link, .link:active, .likeLink {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}
a:active, .link:active, .likeLink:active {
  text-shadow: 0 0 11px;
}
a:hover, .link:hover, .likeLink:hover {
  text-decoration: none;
}