@import "src/variables.module";

.chooseBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $baseSpace;
  border: 2px dashed black;
  margin-top: $baseSpace;
  border-radius: $smallBorderRadius;

  >div {
    margin: $baseSpace;
    font-size: 2em;
    transition: color $transitionDuration ease;
    cursor: pointer;

    &:hover {
      color: $colorLogo;
    }
  }
}

.blocksWrap {
  >div {
    margin-top: $baseSpace;
  }
}

@keyframes showHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.saveLoading {
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: $colorLogo;
  text-align: right;
  font-size: 1.5em;
  margin-top: $baseSpace;
  z-index: 10;
  overflow: hidden;
  animation-name: showHide;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  max-height: 0;
  transition: max-height $transitionDuration ease;

  &.show {
    max-height: 100px;
  }
}

.status {
  margin-top: $baseSpace;
}