@import "src/variables.module";

.introImage {
  max-width: 100%;
  max-height: 200px;
  border-radius: $smallBorderRadius;
}

.introImageWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $baseSpace;
  margin-bottom: $baseSpace;
}

.macros {
  padding: $baseSpace;
  background-color: rgb(230,230,230);
  border-radius: $smallBorderRadius;
}

.ingredientsButton {
  margin-top: $baseSpace;
}