@import "src/variables.module";

.wrap {

}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cross {
  height: 30px;
  width: 30px;
  position: relative;
  margin-bottom: $baseSpace/3;
  cursor: pointer;

  div {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: black;
    border-radius: 2px;
    transition: background-color $transitionDuration ease;
    top: 13px;
    left: 0;
  }

  div:first-child {
    transform: rotate(45deg);
  }

  div:last-child {
    transform: rotate(-45deg);
  }

  &:hover {
    div {
      background-color: $colorLogo;
    }
  }
}