.pack {
  position: relative;
}

.leftTop, .centerTop, .rightCenter, .centerBottom, .leftBottom {
  position: absolute;
  z-index: -1;
}

.leftTop { width: 100px; height: 310px; }
:export { leftTopW: 100; leftTopH: 310 }

.centerTop { width: 233px; height: 226px; }
:export { centerTopW: 233; centerTopHeight: 226; }

.rightCenter { width: 100px; height: 456px; }
:export { rightCenterW: 100; rightCenterH: 456 }

.centerBottom { width: 189px; height: 43px; }
:export { centerBottomW: 189; centerBottomH: 43 }

.leftBottom { width: 655px; height: 607px; }
:export { leftBottomW: 655; leftBottomH: 607 }