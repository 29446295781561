@import "src/variables.module";

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: $baseSpace*3;
  padding-right: $baseSpace*3;
  flex-wrap: wrap;
  position: fixed;
  width: 100%;
  top: 0; left: 0;
  z-index: 3;

  @media all and (max-width: $smallWidth) {
    padding-left: $baseSpace*2;
    padding-right: $baseSpace*2;
  }
}

.logo {
  height: 100%;
  position: absolute;
  left: $baseSpace*3; top: 0;
  cursor: pointer;

  @media all and (max-width: $smallWidth) {
    left: $baseSpace*2;
  }
}

.logoWithoutBg {
  position: absolute;
  height: calc(60px - #{$baseSpace});
  top: $baseSpace*0.5; left: $baseSpace*3;
  cursor: pointer;

  @media all and (max-width: $smallWidth) {
    left: $baseSpace*2;
  }
}

.items {
  font-weight: 600;

  @media all and (max-width: $mediumWidth) {
    font-size: 0.9em;
  }
}