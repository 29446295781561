@import "src/variables.module";
@import "src/mixins";

.editWrap {
  margin: $baseSpace 0;
}

.mainImage {
  margin: $baseSpace 0;
}

.mainImageWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    border-radius: $smallBorderRadius;
    max-width: 100%;
    max-height: 500px;
    cursor: pointer;
  }
}

.textBlock {
  @include quillParagraphs;
}

.iframe {
  width: 100%;
  height: 400px;
  border-radius: $smallBorderRadius;
}

.youtubeVideo {
  margin-top: $baseSpace;
  margin-bottom: $baseSpace;
}

.category {
  text-transform: uppercase;
  color: $colorSpecial;
}

.macrosWrap {
  border-radius: 10px;
  border: 3px solid $colorSpecial;
  padding: $baseSpace;
  margin: $baseSpace*2 0 $baseSpace 0;

  h3 {
    margin-top: 0;
    color: $colorSpecial;
    text-align: center;
  }
}

.macros {
  display: flex;

  >div {
    flex: 50%;
  }

  .chartWrap {
    svg {
      height: 200px;
    }
  }

  .textWrap {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    color: $colorSpecial;
    font-weight: bold;

    >div {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .textCalories {
      font-size: 1.2em;
      color: black;
    }
    .textProteins {
      color: $colorLogo;
    }
    .textCarbohydrates {
      color: $colorSpecial;
    }
    .textFats {
      color: $slightyDarkGrayColor;
    }
  }

  @media all and (max-width: $mSmallWidth){
    flex-direction: column;

    .textWrap {
      >div:not(.textCalories) {
        margin-top: $baseSpace/2;
      }
    }
  }
}

.ingredientsBlock {
  padding: $baseSpace;
  border-radius: 10px;
  border: 3px solid $colorSpecial;
  text-align: center;

  h3 {
    margin-top: 0;
    color: $colorSpecial;
  }

  .ingredient {
    margin: $baseSpace/2 0;
  }
}

.content {
  margin-top: $baseSpace*3;
}