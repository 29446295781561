@import "src/variables.module";

.pack {
  position: fixed;
  top: 0; left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%; height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;
}

.modal {
  background-color: white;
  position: relative;
  margin: $baseSpace auto;
  max-width: 760px;
  padding: $baseSpace $baseSpace*2;
  border-radius: $smallBorderRadius;

  @media all and (max-width: $smallWidth) {
    margin: 0 auto;
  }

  p {
    line-height: 1.5em;
    text-indent: 2em;
    text-align: justify;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading {
  font-size: 2em;
  color: $colorLogo;
}

.closeBtn {
  font-size: 3em;
  color: $colorLogo;
  cursor: pointer;
  transition: color $transitionDuration ease;

  &:hover {
    color: $colorSpecial;
  }

  &:active {
    text-shadow: 0 0 11px #DEA7BA;
  }
}

.line {
  height: 2px; width: 30%;
  background-color: $colorLogo;
  border-radius: 2px;
}

.content {

}

.footer {

}