@import "src/variables.module";

.copyright {
  margin-top: $largeSpace/2;
}

.content {
  flex-grow: 1;
}

.wrap {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}