@import "src/variables.module";

.wrap {
  display: flex;
  width: 100%;
  margin: $baseSpace 0 $baseSpace/2 0;
}

.searchBtn {
  background-color: $colorSpecial;
  order: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 $baseSpace;
  border-radius: 0 $smallBorderRadius $smallBorderRadius 0;
  color: white;
  transition: background-color $transitionDuration ease;
}

.input {
  order: 1;
  margin: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  flex-grow: 1;
  width: 50px;

  &:focus ~ .searchBtn {
    background-color: $colorLogo;
  }
}