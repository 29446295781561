@import "src/variables.module";

.instagramFeed {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$baseSpace;
  margin-right: -$baseSpace;

  a {
    flex: 30%;
    padding: $baseSpace;
    position: relative;

    @media all and (max-width: $xSmallWidth) {
      flex: 100%;
      &:first-of-type { margin-top: 0; }
    }

    img {
      max-width: 100%;
      border-radius: $smallBorderRadius;
    }

    &:before {
      content: "zjisti více";
      color: $colorLogo;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(255,255,255,0.8);
      opacity: 0;
      transition: opacity $transitionDuration ease;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
}