@import "src/variables.module";
@import "src/mixins";

.introText {
  text-align: right;
  margin-top: $baseSpace*4;

  @include whiteStronglyShadowedText;
}

.introTextMalina {
  color: $colorLogo;
}

.introBlock {
  z-index: 2;
}

.aboutUsBlock {
  z-index: 1;
  padding-top: $diagonalHeight+$largeSpace;
  padding-bottom: $largeSpace;
  background-image: url("../files/images/kata3.jpeg");
  background-position: center center;
  background-color: white;
  background-size: cover;
  font-weight: 600;

  color: white;
  text-shadow: 0 0 11px black, 0 0 20px black, 0 0 20px black;
  text-align: justify;
}

.ourGraphic {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $baseSpace;

  img {
    max-width: 100%;
    max-height: 500px;
  }
}

.reviewBlock {
  padding-top: $largeSpace;
}

.servicesBlock {
  background-color: black;
  text-align: center;
  color: white;
  padding-top: $largeSpace;
  padding-bottom: $largeSpace;
}

.vipBlock {
  background-image: $goldGradient;
  padding-top: $largeSpace;
  padding-bottom: $largeSpace;
  text-align: center;
  margin-top: $largeSpace;

  >section>div {
    display: block;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 5em;
    margin-bottom: $baseSpace;
  }
}

.bottomMenu {
  font-weight: 600;
  padding-top: $baseSpace*2;
  padding-bottom: $baseSpace*2;
  background-color: $darkGrayColor;

  svg {
    height: 100px;
  }
}

.bottomMenuInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;

  @media all and (max-width: $mediumWidth) {
    flex-direction: column;
    justify-content: center;
  }
}

.socialWrap {
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.contactName {
  margin-top: 0;
  margin-bottom: 0;
}

.contactDesc {
  font-size: 0.8em;
}

.facebookIco {
  height: 1em;
  margin-right: 5px;
}

.line {
  margin: $baseSpace*2 auto;
}