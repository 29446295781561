@import "src/variables.module";

.doneBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  border-radius: $smallBorderRadius;
  padding: $baseSpace*2;
}

.checkCircleBlock {
  font-size: 4em;
  color: $successColor;
}

.centerBlock {
  text-align: center;
}

.heading {
  color: $successColor;
  font-weight: bold;
}

.macroBlock {
  margin: $baseSpace 0;
}

.caloriesBlock {
  font-size: 1.2em;
  font-weight: bold;
}

.infoBlock {
  font-style: italic;
  font-size: 0.8em;
  margin: $baseSpace 0;
}

.button {
  margin-top: $baseSpace;
}