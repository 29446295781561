@import "src/variables.module";

.section {
  position: relative;
  padding-bottom: $baseSpace*5;
}

.after {
  content: '';
  position: absolute;
  bottom: -$diagonalHeight;
  left: 0;
  width: 100%;
  height: calc(100% + #{$diagonalHeight});
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - #{$diagonalHeight}), 0 100%);
  background-size: cover;
  background-position: top;
  z-index: -1;
}