@import "src/variables.module";
@import "src/mixins";

$raspberryOutSize: 10rem;
:export { raspberryOutSize: 10rem }

.item {
  background-color: $colorLogo;
  color: white;
  text-align: center;
  border-radius: $smallBorderRadius;
  padding: $baseSpace*2;
  position: absolute;
}


.name {
  text-transform: uppercase;
  margin-top: $baseSpace*2;
  font-size: 1.2em;
  font-weight: 600;
}

.content {
  margin-top: $baseSpace;
  font-size: 0.9em;
  line-height: 1.4em;
}

.image {
  width: 100%;
  display: block;
  margin-top: -$raspberryOutSize;
}