@import "../../variables.module";


$btnsSpacing: $baseSpace/3;
.wrapBtns {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$btnsSpacing;
  margin-right: -$btnsSpacing;
  >* {
    margin-left: $btnsSpacing;
    margin-bottom: $btnsSpacing;
  }
}