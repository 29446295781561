@import "src/variables.module";

.title {
  font-size: 4em;
  font-weight: 600;

  @media all and (max-width: $xSmallWidth) {
    font-size: 3em;
  }
}

.subtitle {
  font-size: 2em;
  margin-top: $baseSpace;

  @media all and (max-width: $xSmallWidth) {
    font-size: 1.3em;
  }
}