@import "src/variables.module";

@mixin whiteShadowedText {
  color: white;
  text-shadow: 0 0 11px black;
}

@mixin whiteStronglyShadowedText {
  color: white;
  text-shadow: 0 0 11px black, 0 0 20px black;
}

@mixin whiteTextShadow {
  text-shadow: 0 0 11px white;
}

@mixin whiteTextShadowDoubled {
  text-shadow: 0 0 11px white, 0 0 11px white;
}

@mixin fontFamily {
  font-family: Montserrat, sans-serif;
  font-size: 1em;
}

@mixin formPartBorders {
  border-radius: 5em;
  border-style: none;
}

@mixin quillParagraphs {
  p {
    max-width: $smallWidth;
    margin-top: $baseSpace;
    margin-bottom: $baseSpace;
    line-height: 1.5em;
    text-indent: 2em;
    text-align: justify;
  }

  h2 {
    color: $colorLogo;
  }

  h3, h4 {
    color: $colorSpecial;
  }

  ul, ol {
    margin-top: $baseSpace;
    margin-bottom: $baseSpace;
  }

  ul > li {
    list-style-type: disc;
  }

  a {
    color: black;
  }
}