@import "src/variables.module";

.crop {
  margin-top: $baseSpace;
}

.footer {
  margin-top: $baseSpace;
  display: flex;
  justify-content: space-between;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
}