@import "src/variables.module";

.wrap {

}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $colorSpecial;
  color: white;
  padding: $baseSpace/2 $baseSpace;
  margin-top: $baseSpace;
  border-radius: $smallBorderRadius;
  cursor: pointer;
  transition: background-color $transitionDuration ease;

  &:hover {
    background-color: $colorLogo;
  }
}