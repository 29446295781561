@import "src/variables.module";

.wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.insideBlock {
  text-align: center;
}

.iconWrap {
  font-size: 6em;
  color: $colorLogo;
  margin-bottom: $baseSpace;
}

.textBlock {
  margin: $baseSpace 0 $baseSpace*2 0;
}

.loading {
  width: 50px;
  height: 50px;
}