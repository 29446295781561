@import "src/variables.module";

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: $baseSpace/2;

  >div {
    display: flex;
    align-items: center;

    >div:nth-child(2), >div:nth-child(4) {
      margin: 0 10px;
    }
  }

  .arrow {
    font-size: 1.4em;
    transition: color $transitionDuration ease;
    cursor: pointer;

    &:hover {
      color: $colorLogo;
    }

    &.disabled {
      color: $lightColor;
      cursor: default;
      &:hover {
        color: $lightColor;
      }
    }
  }
}